import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_UFMCqFAmT_rT4NcMBSUgq5IuOGNlCK5IH_2sTbP0mGc from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import Sentry_client_j0x2mLsedyV5Vq1fljdIGymEk83H_lcnsvG3ozmTlCQ from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/plugins/Sentry.client.ts";
import breakpoints_client_K0pbqXMbQL_Uouylk6F0YUZ9CDEtoc1RZ6_nE1W5MVc from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/plugins/breakpoints.client.ts";
import dynamic_assets_client_PbtavGEOxY6Q6IpmYx9jcqtZ5sD2pbmRwYq580hxYWc from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/plugins/dynamic-assets.client.ts";
import globals_l0LLwvMn5OXIJiW8MvIXaLXJNj9B5qMK_SI88d520HI from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/plugins/globals.ts";
import ofetch_fyCWtViSU3Rrp_B1klk5HGwMIElaVJPI_vc3oHXcgSI from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/plugins/ofetch.ts";
import scroll_observer_client_6fl7e_jQm16h1F6BovcSaU_ebOpe4hwbaxaufn3PXAk from "/home/cleavr/www.cupohosting.com/releases/20250410080823411/plugins/scroll-observer.client.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  nuxt_plugin_UFMCqFAmT_rT4NcMBSUgq5IuOGNlCK5IH_2sTbP0mGc,
  Sentry_client_j0x2mLsedyV5Vq1fljdIGymEk83H_lcnsvG3ozmTlCQ,
  breakpoints_client_K0pbqXMbQL_Uouylk6F0YUZ9CDEtoc1RZ6_nE1W5MVc,
  dynamic_assets_client_PbtavGEOxY6Q6IpmYx9jcqtZ5sD2pbmRwYq580hxYWc,
  globals_l0LLwvMn5OXIJiW8MvIXaLXJNj9B5qMK_SI88d520HI,
  ofetch_fyCWtViSU3Rrp_B1klk5HGwMIElaVJPI_vc3oHXcgSI,
  scroll_observer_client_6fl7e_jQm16h1F6BovcSaU_ebOpe4hwbaxaufn3PXAk
]